import { useEffect, useState } from "react";
import "./App.css";
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { Dashboard } from './components/admin/dashboard';
import SessionExpiredPopup from "./components/SessionExpiredPopup";
import axios from "axios";
import Activation from "./components/user/activation";


export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 80;
    const apiUrl =  process.env.REACT_APP_API_URL  ||  `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getOvertimeTrackerApi() {
    const apiUrl =  process.env.REACT_APP_HOTSCHEDULES_API_URL  ||  `http://localhost:8080`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_PORT ? process.env.REACT_APP_WEBSITE_URL+":"+process.env.REACT_APP_WEBSITE_PORT : process.env.REACT_APP_WEBSITE_URL : `http://localhost:${websitePort}`;
    return websiteUrl;
}
console.log(getApiDomain());
console.log(getWebsiteDomain());
SuperTokens.init({
    appInfo: {
        appName: "CruSync", // TODO: Your app name
        apiDomain: getApiDomain(), // TODO: Change to your app's API domain
        websiteDomain: getWebsiteDomain(), // TODO: Change to your app's website domain
    },
    recipeList: [
        EmailPassword.init({
            emailVerificationFeature: {
                mode: "REQUIRED",
            },
        }),
        Session.init(),
    ],
});

function App() {
    let [showSessionExpiredPopup, updateShowSessionExpiredPopup] = useState(false);
    /*useEffect(()=>{
        axios.get("https://api.crusync.com/sessioninfo").then((res)=>{
            console.log(res);
            console.log("Session info")
        })
    }, [])*/
    return (
        <div className="App">
            <Router>
                    <div className="fill">
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
                            <Route
                                path="/activate/:token/:emailtoken"
                                element={<Activation />}
                            />

                            <Route
                                path="/*"
                                element={
                                    /* This protects the "/" route so that it shows 
                                    <Home /> only if the user is logged in.
                                    Else it redirects the user to "/auth" */
                                    <EmailPassword.EmailPasswordAuth
                                        onSessionExpired={() => {
                                            updateShowSessionExpiredPopup(true);
                                        }}>
                                        <Dashboard />
                                        {showSessionExpiredPopup && <SessionExpiredPopup />}
                                    </EmailPassword.EmailPasswordAuth>
                                }
                            />
                        </Routes>
                    </div>
            </Router>
        </div>
    );
}

export default App;
