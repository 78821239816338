import React, {useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {Box, Menu, MenuItem, Button, IconButton, CssBaseline, AppBar, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import {HotSchedulesProvider} from '../../context/HotSchedules'

import { UserDashboard } from './users';
import { Route, Routes} from 'react-router-dom';
import { UserProfile } from './userprofile';
import BohScheduleOverview from "../BOH/BohScheduleOverview"
import {SideMenu} from './sidemenu';
import BohTeamsheet from '../BOH/BohTeamsheets';
import Teams from '../GiftCards/Teams/Teams';
import GiftCardOverview from '../GiftCards/Overview';
import EmployeeOverview from '../GiftCards/Employees/EmployeeOverview';
import EmployeeOverviewByDate from '../GiftCards/Employees/EmployeeByDate';
import RequestOffDashboard from "../Employees/RequestOffDashboard";
import SeasonOverview from '../GiftCards/Season/SeasonOverview';
import { GiftCardProvider } from "../../context/GiftCards";
import InventoryOverview from '../BOH/Inventory/InventoryOverview';
import EmployeePayOverview from '../EmployeePay/EmployeePayOverview';
import ImportBlast from '../FOH/ImportBlast';
import ServerList from '../FOH/ServerList';
const drawerWidth = 240;

export const Dashboard = ({children, ...rest}) => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <React.Fragment>
                <AppBar
                    sx={{ 
                        width: {sm:`100%`, md:`calc(100% - ${drawerWidth}px)`}, 
                        ml: {sm:`0px`, md:`${drawerWidth}px`}
                    }}
                >
                    <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open menu"
                        edge="start"
                        onClick={handleMenu}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                        <Typography 
                            variant="h6" 
                            noWrap 
                            component="div" 
                            sx={{
                                flexGrow: 1,
                                textAlign: "left"
                            }}
                        >
                            CruSync
                        </Typography>
                        <AccountButton />
                    
                    </Toolbar>
                </AppBar>
                <SideMenu open={menuOpen} handleClose={handleMenu} />
                <Box        
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                >
                    <Toolbar />
                    <HotSchedulesProvider>
                        <Routes>
                            <Route path="/" element={(<React.Fragment>Dashboard</React.Fragment>)} />
                            <Route path="/dashboard/users/*" element={<UserDashboard />}>
                                </Route>
                            <Route path="/dashboard/requestoff/dashboard" element={<RequestOffDashboard />}>

                            
                            </Route>
                            <Route path="/dashboard/employee/payrates" element={<EmployeePayOverview />}>
                            </Route>
                            <Route path="/user/:id" element={<UserProfile />}>
                            </Route>
                            <Route path="/dashboard/teamsheets/boh" element={<BohTeamsheet />} />
                            <Route path="/dashboard/giftcards/*" element={                                <React.Fragment>
                                    <GiftCardProvider>
                                        <Routes>
                                            <Route path="teams/*" element={<GiftCardOverview />} />
                                            <Route path="employees/*" element={<EmployeeOverview />} />
                                            <Route path="season" element={<SeasonOverview />} />
                                        </Routes>
                                    </GiftCardProvider>
                            </React.Fragment>} />
                            <Route path="/dashboard/foh/blast/upload"
                                element={<ImportBlast />}
                                />
                            <Route path="/dashboard/foh/serverlist"
                                element={<ServerList />}
                            />
                            <Route 
                                path="/dashboard/inventory/*" 
                                element={
                                    <React.Fragment>
                                        <Routes>
                                            <Route path="kitchen/*" element={<InventoryOverview />} />
                                        </Routes>
                                    </React.Fragment>
                                }
                            />
                            <Route path="/dashboard/schedule/boh/overview" element={<BohScheduleOverview />} />
                        </Routes>
                    </HotSchedulesProvider>
                </Box>
            </React.Fragment>

        
        </Box>
    )
}

const AccountButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = async () => {
        await signOut();
        navigate("/auth")
    }

    return(
       <React.Fragment>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
       </React.Fragment> 
    )
}
