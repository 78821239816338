import React, {useEffect, useState} from 'react';
import axios from "axios";
import SuperTokensRequest from 'supertokens-website';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {Box, Button, ButtonGroup, TableBody, Paper, TableContainer, TableHead, TableRow, Table,
        Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, CircularProgress,
        Alert, AlertTitle, Collapse, Autocomplete
} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import { getApiDomain } from "../../App";



SuperTokensRequest.addAxiosInterceptors(axios);

const url = getApiDomain();

export const UserDashboard = (props) => {
    const [users, setUsers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [userCreationOpen, setUserCreationOpen] = useState(false);
    const nav = useNavigate();
    const deleteUser = async (userId) =>  {
        await axios.delete(url + "/user/" + userId);
        loadUsers();
    }
    const handleUserCreation = () => {
        setUserCreationOpen(!userCreationOpen);
    }
    const loadEmployees = async () => {
        axios.get(url + "/employees").then((res)=>{
            console.log(res.data);
            let tempEmployees = res.data.employees.sort((a, b)=>{
                return a.name.localeCompare(b.name)
            })
            setEmployees(tempEmployees)
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const loadUsers = async () =>{
        axios.get(url + "/users")
        .then((res)=>{
            console.log(res.data)
            if(res.data.userList === null){
                setUsers([]);
            } else {
                setUsers(res.data.userList)
            }
        })
        .catch((error)=>{
            console.log(error.response);

        })
    }

    useEffect(()=>{
        loadUsers();
        loadEmployees();
        console.log("users")
    }, [])


    return(

            <Box

            >
                <UserCreationDialog employees={employees} open={userCreationOpen} handleClose={handleUserCreation} loadUsers={loadUsers}/>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                    }}
                >
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={handleUserCreation}>Add</Button>
                        <Button>Modify</Button>
                    </ButtonGroup>
                </Box>
                <div style={{ height: 400, width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                minWidth: 700,
                            }}
                            aria-label="Active Users"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        First Name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Last Name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Email
                                    </StyledTableCell>
                                    <StyledTableCell>
                                       
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user)=>{
                                    return(
                                    <StyledTableRow 

                                        hover 
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                        key={user.id}>
                                        <StyledTableCell 
                                            onClick={()=>{
                                                nav(`/dashboard/user/${user.id}`)
                                            }}
                                            component="th" scope="row">
                                            {user.first_name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {user.last_name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {user.email}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button
                                                onClick={()=>{
                                                    deleteUser(user.id)
                                                }}
                                            >Delete</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>);
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>

    )
}

const UserCreationDialog = (props) => {
    const {open, handleClose, loadUsers} = props;
    const [loading, setLoading] = useState(false);
    const [errorCreating, setError] = useState(false);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "", 
        id: ""
    })
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const {employees} = {...props}

    const handleInputChange = (key, value) => {
        const tempUserData = {...userData};
        tempUserData[key] = value;
        setUserData({...tempUserData})
    }

    const submitNewUser = () => {
        setLoading(true);
        axios.post(url + "/register", userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res)=>{
            setLoading(false);
            setUserData({
                firstName: "",
                lastName: "",
                email: "",
                id: "",
            });
            setError(false);
            loadUsers();
            handleClose();
        })
        .catch((error)=>{
            setLoading(false);
            setError(true);
        })
    }
    const handleCloseMain = () => {
        setUserData({
            firstName: "",
            lastName: "",
            email: "", 
            id: ""
        });
        handleClose();
    }
    return(
        <Dialog 
            open={open} 
            onClose={handleCloseMain}
        >
            <DialogTitle>Create a new user</DialogTitle>

            <DialogContent>
            <React.Fragment>
                <Collapse in={errorCreating}>
                    <Alert severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        There was an error creating {userData.firstName}'s account
                    </Alert>
                </Collapse>                
                <Collapse in={loading}>
                    <Alert severity="info"
                    >
                        <AlertTitle>Processing</AlertTitle>
                            Creating {userData.firstName + " " + userData.lastName}'s account.
                    </Alert>
                </Collapse>
                <DialogContentText
                    sx={{
                        marginBottom: "8px"
                    }}
                >
                        Creating a new user will email the user a temporary password to use to create an account. The first time he/she logs in, he/she will have to create a new password. The email will be their username. 
                </DialogContentText>
                <Autocomplete
                        autoFocus
                        disablePortal
                        id="employee-names"
                        options={employees}
                        getOptionLabel={(option)=>option.name}
                        onChange={(event, employee)=>{
                            console.log(employee)
                            let tempEmployee
                            if(employee){

                                tempEmployee = {
                                    firstName: employee.firstname,
                                    lastName: employee.lastname,
                                    email: employee.email,
                                    id: employee.id
                                }
                            } else {
                                tempEmployee = {
                                    firstName: "",
                                    lastName: "",
                                    email: "", 
                                    id: ""
                                }
                            }

                            setUserData({...tempEmployee})
                            setSelectedEmployee(employee)
                            
                        }}
                        renderInput={(params) => <TextField {...params} label="Employee Name" />}
                        style={{
                            width: "100%"
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%"
                        }}
                    >

                        <TextField
                            id="firstName"
                            label="First Name"
                            type='text'
                            margin="normal"
                            value={userData.firstName}
                            onChange={(e)=>{
                                handleInputChange("firstName", e.target.value)
                            }}
                            style={{
                                marginRight: "4px",
                                width: "50%"
                            }}
                        />                        
                        <TextField
                            id="lastName"
                            label="Last Name"
                            type='text'
                            margin="normal"
                            value={userData.lastName}
                            onChange={(e)=>{
                                handleInputChange("lastName", e.target.value)
                            }}
                            style={{
                                marginRight: "4px",
                                width: "50%"
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%"
                        }}
                    >
                        <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type='text'
                                value={userData.email}
                                onChange={(e)=>{
                                    handleInputChange("email", e.target.value)
                                }}
                                fullWidth
                                style={{
                                    marginRight: "4px",
                                    width: "75%"
                                }}
                        />
                        <TextField
                            margin="dense"
                            id="employeeid"
                            label="Hotschedules ID"
                            type='text'
                            value={userData.id}
                            fullWidth
                            style={{
                                marginRight: "4px",
                                width: "25%"
                            }}
                        />
                    </Box>
            </React.Fragment>

            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCloseMain}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={submitNewUser}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#F8F8F8",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));