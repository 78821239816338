import React, {useState} from "react";
import {Drawer, Toolbar, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {useNavigate} from 'react-router-dom';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import GroupsIcon from '@mui/icons-material/Groups';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import CountertopsIcon from '@mui/icons-material/Countertops';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const drawerWidth = 240;

export const SideMenu = (props)=>{
    const {open, handleClose} = {...props}
    return(
        <React.Fragment>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    display: { sm: 'none', xs: 'none', md: 'block' },
                }}
                variant="permanent"
                anchor="left"
            >
                <MenuContent />            
            </Drawer>
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleClose}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },

                  }}
            >
                <MenuContent />
            </Drawer>
        </React.Fragment>
    )
}

const MenuContent = (props) =>{
    const [parMenuOpen, setParMenuOpen] = useState(true);
    const nav = useNavigate();
    const {userId} = useSessionContext();
    const [open, setOpen] = React.useState(true);
    const [bohOpen, setBohOpen] = React.useState(true);
    const [fohOpen, setFohOpen] = React.useState(true);

    const handleClick = () => {
      setOpen(!open);
    };
    const handleBohClick = () => {
        setBohOpen(!bohOpen);
      };
      const handleFohClick = () => {
        setFohOpen(!fohOpen);
      };
    const handleParMenu = () => {
        setParMenuOpen(!parMenuOpen)
    }
    return(
        <React.Fragment>
            <Toolbar />
            <Divider />
            <List>
                {("70266260-20f1-48b8-acdb-0bc0bf1a949c" === userId || userId === "40abbda4-cf76-46d5-bb73-a0619122dbb9") ? 
                <ListItem button
                    onClick={()=>{
                        nav("/dashboard/users")
                    }}
                >
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
                : ""}
                <ListItem button
                        onClick={()=>{
                        nav("/dashboard/requestoff/dashboard" )
                    }}
                >
                    <ListItemIcon>
                        <PeopleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="R/O Dashboard" />
                </ListItem>
                <ListItem  button onClick={handleBohClick}>
                    <ListItemIcon>
                        <CountertopsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Back of House" />
                    {bohOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={bohOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem 
                            sx={{ pl: 4 }}
                            button
                            onClick={()=>{
                                nav("/dashboard/teamsheets/boh")
                            }}
                        >
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText primary="Teamsheets" />
                        </ListItem>
                        <ListItem 
                            sx={{ pl: 4 }}
                            button
                            onClick={()=>{
                                nav("/dashboard/schedule/boh/overview")
                            }}
                        >
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schedule Overview" />
                        </ListItem>
                        <ListItem  
                            sx={{ pl: 4 }}
                            button
                            onClick={()=>{
                                nav("/dashboard/inventory/kitchen")
                            }}
                        >
                            <ListItemIcon>
                                <InventoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inventory" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem  button onClick={handleFohClick}>
                    <ListItemIcon>
                        <CountertopsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Front of House" />
                    {fohOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={fohOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem 
                                sx={{ pl: 4 }}
                                button
                                onClick={()=>{
                                    nav("/dashboard/foh/blast/upload")
                                }}
                            >
                                <ListItemIcon>
                                    <DriveFolderUploadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Blast Upload" />
                        </ListItem>
                        <ListItem 
                                sx={{ pl: 4 }}
                                button
                                onClick={()=>{
                                    nav("/dashboard/foh/serverlist")
                                }}
                            >
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Server List" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem  button onClick={handleClick}>
                    <ListItemIcon>
                        <CardGiftcardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gift Cards" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItem button sx={{ pl: 4 }}
                            onClick={()=>{
                                nav("/dashboard/giftcards/season" )
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Gift Card Seasons" />
                        </ListItem>
                        <ListItem button sx={{ pl: 4 }}
                            onClick={()=>{
                                nav("/dashboard/giftcards/teams" )
                            }}
                        >
                            <ListItemIcon>
                                <GroupsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Teams" />
                        </ListItem>
                        <ListItem button sx={{ pl: 4 }}
                            onClick={()=>{
                                nav("/dashboard/giftcards/employees" )
                            }}
                        >
                            <ListItemIcon>
                                <PeopleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Server Totals" />
                        </ListItem>
                    </List>
                </Collapse>
                
            </List>
        </React.Fragment>
    )
}