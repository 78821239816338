import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Backdrop, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams, useNavigate} from 'react-router-dom';
import SuperTokensRequest from 'supertokens-website';
import axios from "axios";
import { getApiDomain } from '../../App';

SuperTokensRequest.addAxiosInterceptors(axios);
const url = getApiDomain();


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://crusync.com/">
        Crusync
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Activation() {
  const [error, setError] = React.useState(false);
    const[message, setMessage] = React.useState("Your passwords do not match.")
  const nav = useNavigate();

  const { token, emailtoken } = useParams();
  console.log(emailtoken)
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let password = data.get('password');
    let confirmPassword = data.get('password2')
    if(password !== confirmPassword){
        setError(true);
        setMessage("Passwords do not match");        
        return;
    }
    const dataDict = {
        password: data.get('password'),
        emailtoken: emailtoken,
        token: token
    }
    axios.post(url + "/changepassword", dataDict).then((res)=>{
        console.log(res.data);
        setError(false);
        nav("/auth")

    })
    .catch((error)=>{
        if(error.response){
            if(error.response.status === 408){
                setError(true);
                setMessage("Invalid tokens")
            } else {
                setError(true);
                setMessage("Could not be activated")
            }
        } else {
            setError(true);
            setMessage("Could not be activated")
        }
    })
    // eslint-disable-next-line no-console

  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create your password to activate
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {error ? message : ""}
            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              name="password2"
              label="Confirm Password"
              type="password"
              id="password2"
              autoComplete="current"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Activate
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}