import React, { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';

import SuperTokensRequest from 'supertokens-website';
import { Box, Menu, MenuItem, IconButton, Paper, Snackbar, Tab, Table, TableBody, TableCell, Button, TableRow, Tabs, Typography, ListItemIcon, ListItemText } from "@mui/material";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../../../App";
import {getStartOfWeek} from "../../../functions";
import moment from 'moment-timezone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SyncIcon from '@mui/icons-material/Sync';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

import Excel, { Workbook } from "exceljs"
import { KeyboardReturnOutlined } from "@mui/icons-material";
import { Columns } from "react-feather";

moment.updateLocale('en', {
    week: {
        dow: 3
    }
});
SuperTokensRequest.addAxiosInterceptors(axios);
const url = getApiDomain();

function formatMoney (number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    sign += "$"
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}



export default function BohTeamsheet() {
    //const { userId } = useSessionContext();
    const [teamsheets, setTeamsheet] = useState({});
    const [employees, setEmployees] = useState({});
    const [locations, setLocations] = useState({});
    const [jobcodes, setJobcodes] = useState({});
    const [startOfWeek, setStartOfWeek] = useState(getStartOfWeek(moment()));
    const [jobcodesKeys, setJobcodesKeys] = useState([]);
    const [currentTab, setCurrentTab] = useState(moment().weekday());
    const [currentDay, setCurrentDay] = useState(moment(startOfWeek).add(currentTab, "days"));
    const [failLoad, setFailLoad] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarState, setSnackbarState] = useState(false);

    const [fiscalInfo, setFiscalInfo] = useState({});
    const [salesData, setSalesData] = useState({});
    const [totalMinutes, setTotalMinutes] = useState({});
    const [totalCost, setTotalCost] = useState({});
    const [totalOvertimeCost, setTotalOvertimeCost] = useState({});
    const [totalOvertimeMinutes, setTotalOvertimeMinutes] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    const roles = {
        "1024889472": "BOH Utility",
        "1024889474": "Cold Prep",
        "1024889502": "Hot Prep",
        "1024889504": "Meat Cut",
        "1024889508": "Point",
        "641344898": "Kitchen ",
        "641344899": "Dish",
        "643775091": "Train BOH",
        "643775092": "Expo",
        "644758559": "Bakery",
        "644758565": "Broil",
        "644758573": "Fry",
        "644758575": "Line",
        "644758578": "Prep",
        "644758586": "Salad",
    };
    const enableSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarState(true);
    }
    const snackbarClose = () => {
        setSnackbarState(false);
    }
    const downloadLaborTracker = async  () => {
        var days = ["Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday", "Tuesday"];
        const newWeek = moment(startOfWeek);
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("BOH Daily")
        const borderColor = '000000';
        const borderStyle =  {
            top: {style: 'thin', color: {argb: borderColor}},
            left: {style: 'thin', color: {argb: borderColor}},
            bottom: {style: 'thin', color: {argb:borderColor}},
            right: {style: 'thin', color: {argb:borderColor}}
        };
        worksheet.getColumn(1).width = 12;
        worksheet.getColumn(2).width = 2;
        worksheet.getColumn(3).width = 19;
        worksheet.getColumn(4).width = 2;
        worksheet.getColumn(5).width = 19;
        worksheet.getColumn(6).width = 2;
        worksheet.getColumn(7).width = 19;
        worksheet.getColumn(8).width = 2;
        worksheet.getColumn(9).width = 19;
        worksheet.getColumn(10).width = 19;
        worksheet.getColumn(11).width = 19;
        worksheet.getColumn(12).width = 2;
        worksheet.getColumn(13).width = 19;
        worksheet.getColumn(14).width = 19;
        worksheet.getColumn(15).width = 19;
        worksheet.getColumn(16).width = 77;

        let startRowIndex = 3;

        //
            let currentRowIndex = startRowIndex;
            for(let x = 0; x < 7; x++){
                // Day of week Cell
                worksheet.mergeCells(currentRowIndex, 1, currentRowIndex + 6, 1)
                worksheet.getCell( "A"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "A"+ currentRowIndex).value = days[x];
                worksheet.getCell( "A"+ currentRowIndex).alignment = { vertical: 'middle', horizontal: 'center' };
                // Blank Cell with header cell
                worksheet.getCell( "B"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("B"+(currentRowIndex + 1) + ":B"+(currentRowIndex + 6))            
                worksheet.getCell( "B"+ (currentRowIndex + 1)).border = borderStyle;
                
                // Managers on Duty
                worksheet.getCell( "C"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "C"+ currentRowIndex).value = "MANAGERS ON DUTY";

                worksheet.mergeCells("C"+(currentRowIndex + 1) + ":C"+(currentRowIndex + 2))            
                worksheet.getCell( "C"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("C"+(currentRowIndex + 3) + ":C"+(currentRowIndex + 4))            
                worksheet.getCell( "C"+ (currentRowIndex + 3)).border = borderStyle;

                worksheet.mergeCells("C"+(currentRowIndex + 5) + ":Cf"+(currentRowIndex + 6))            
                worksheet.getCell( "C"+ (currentRowIndex + 5)).border = borderStyle;
                // Blank Cell with header cell
                worksheet.getCell( "F"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("F"+(currentRowIndex + 1) + ":F"+(currentRowIndex + 6))            
                worksheet.getCell( "F"+ (currentRowIndex + 1 )).border = borderStyle;
                // Blank Cell with header cell
                worksheet.getCell( "D"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("D"+(currentRowIndex + 1) + ":D"+(currentRowIndex + 6))            
                worksheet.getCell( "D"+ (currentRowIndex+1)).border = borderStyle; 
                // Projected Hours
                worksheet.getCell( "E"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "E"+ currentRowIndex).value = "PROJECTED HOURS";

                worksheet.mergeCells("E"+(currentRowIndex + 1) + ":E"+(currentRowIndex + 3))            
                worksheet.getCell( "E"+ (currentRowIndex + 1)).border = borderStyle;
                worksheet.getCell( "E"+ (currentRowIndex + 1)).value = totalMinutes[newWeek.format("YYYY-MM-DD")]/60;

                worksheet.mergeCells("E"+(currentRowIndex + 4) + ":E"+(currentRowIndex + 6))            
                worksheet.getCell( "E"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "E"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }

                // Blank Cell with header cell
                worksheet.getCell( "H"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("H"+(currentRowIndex + 1) + ":H"+(currentRowIndex + 6))            
                worksheet.getCell( "H"+ (currentRowIndex+1)).border = borderStyle; 
                
                // Projected Hours
                worksheet.getCell( "G"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "G"+ currentRowIndex).value = "ACTUAL HOURS";

                worksheet.mergeCells("G"+(currentRowIndex + 1) + ":G"+(currentRowIndex + 3))            
                worksheet.getCell( "G"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("G"+(currentRowIndex + 4) + ":G"+(currentRowIndex + 6))            
                worksheet.getCell( "G"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "G"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }

                // Blank Cell with header cell
                worksheet.getCell( "L"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("L"+(currentRowIndex + 1) + ":L"+(currentRowIndex + 6))            
                worksheet.getCell( "L"+ (currentRowIndex+1)).border = borderStyle;  
                            // Projected Percent
                worksheet.getCell( "I"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "I"+ currentRowIndex).value = "PROJECTED %";

                worksheet.mergeCells("I"+(currentRowIndex + 1) + ":I"+(currentRowIndex + 3))            
                worksheet.getCell( "I"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("I"+(currentRowIndex + 4) + ":I"+(currentRowIndex + 6))            
                worksheet.getCell( "I"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "I"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }   
                worksheet.getCell( "I"+ (currentRowIndex + 1)).value = { formula:"=J"+(currentRowIndex + 1)+"/K"+(currentRowIndex + 1), result: "#DIV/0!"};

                // Projected labor
                worksheet.getCell( "J"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "J"+ currentRowIndex).value = "PROJECTED $";

                worksheet.mergeCells("J"+(currentRowIndex + 1) + ":J"+(currentRowIndex + 3))            
                worksheet.getCell( "J"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("J"+(currentRowIndex + 4) + ":J"+(currentRowIndex + 6))            
                worksheet.getCell( "J"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "J"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }         
                worksheet.getCell( "J"+ (currentRowIndex + 1)).numFmt = '$#,##0.00';
                worksheet.getCell( "J"+ (currentRowIndex + 1)).value = totalCost[newWeek.format("YYYY-MM-DD")]/100;

                //
                // Projected labor
                worksheet.getCell( "K"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "K"+ currentRowIndex).value = "PROJECTED SALES";

                worksheet.mergeCells("K"+(currentRowIndex + 1) + ":K"+(currentRowIndex + 3))            
                worksheet.getCell( "K"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("K"+(currentRowIndex + 4) + ":K"+(currentRowIndex + 6))            
                worksheet.getCell( "K"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "K"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }         
                worksheet.getCell( "K"+ (currentRowIndex + 1)).numFmt = '$#,##0.00';

                //   
                // Actual Percent
                worksheet.getCell( "M"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "M"+ currentRowIndex).value = "ACTUAL %";

                worksheet.mergeCells("M"+(currentRowIndex + 1) + ":M"+(currentRowIndex + 3))            
                worksheet.getCell( "M"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("M"+(currentRowIndex + 4) + ":M"+(currentRowIndex + 6))            
                worksheet.getCell( "M"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "M"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }    
                worksheet.getCell( "M"+ (currentRowIndex + 1)).value = { formula:"=N"+(currentRowIndex + 1)+"/O"+(currentRowIndex + 1), result: "#DIV/0!"};
     
                //      
                // Actual Percent
                worksheet.getCell( "N"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "N"+ currentRowIndex).value = "ACTUAL $";

                worksheet.mergeCells("N"+(currentRowIndex + 1) + ":N"+(currentRowIndex + 3))            
                worksheet.getCell( "N"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("N"+(currentRowIndex + 4) + ":N"+(currentRowIndex + 6))            
                worksheet.getCell( "N"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "N"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }   
                worksheet.getCell( "N"+ (currentRowIndex + 1)).numFmt = '$#,##0.00';
      
                // 
                // Actual Sales
                worksheet.getCell( "O"+ currentRowIndex).border = borderStyle;
                worksheet.getCell( "O"+ currentRowIndex).value = "ACTUAL SALES";

                worksheet.mergeCells("O"+(currentRowIndex + 1) + ":O"+(currentRowIndex + 3))            
                worksheet.getCell( "O"+ (currentRowIndex + 1)).border = borderStyle;

                worksheet.mergeCells("O"+(currentRowIndex + 4) + ":O"+(currentRowIndex + 6))            
                worksheet.getCell( "O"+ (currentRowIndex + 4)).border = borderStyle;
                worksheet.getCell( "O"+ (currentRowIndex + 4)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'808080'}
                }         
                worksheet.getCell( "O"+ (currentRowIndex + 1)).numFmt = '$#,##0.00';

                //
                // Blank Cell with header cell
                worksheet.getCell( "P"+ currentRowIndex).border = borderStyle;
                worksheet.mergeCells("P"+(currentRowIndex + 1) + ":P"+(currentRowIndex + 6))            
                worksheet.getCell( "P"+ (currentRowIndex+1)).border = borderStyle;    
                worksheet.getCell( "P"+ currentRowIndex).value = "VARIANCE EXPLINATION"       
                
                // Total Labor Projection
                worksheet.getCell("C2").value="Projected Weekly"
                worksheet.getCell("I2").value = { formula:"=K2/I2", result: "#DIV/0!"};
                // Weekly Projected sales
                worksheet.getCell("G2").value="Wkly Projected Sales"
                worksheet.getCell("I2").value = { formula:"=K4+K15+K26+K37+K48+K59+K70", result: "#DIV/0!"};
                // Total Weekly Projected Labor
                worksheet.getCell("J2").value="PJCTED LBR $"
                worksheet.getCell("K2").value = { formula:"=J4+J15+J26+J37+J48+J59+J70", result: "#DIV/0!"};

            //
            currentRowIndex += 11;
            newWeek.add(1, "day");
        }
        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), startOfWeek.format("MM-DD-YYYY") + ' Labor Tracker.xlsx')
    }
    const downloadTeamsheets = async () => {
        const tabTitles = ['Wed', 'Thurs', 'Fri', 'Sat', 'Sun', 'Mon', 'Tues'];
        var days = ["Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday", "Tuesday"];
        const workbook = new Excel.Workbook();
        let row = 2;


        for(let i = 0; i<7; i++){
            let index = 0;

            const shifts = teamsheets[moment(startOfWeek).add(i, "days").format("YYYY-MM-DD")];
            const worksheet = workbook.addWorksheet(tabTitles[i]);
            worksheet.getColumn(1).width = 3.71;
            worksheet.getColumn(2).width =  13.57;
            worksheet.getColumn(3).width =  16.57;
            worksheet.getColumn(4).width =  14;
            worksheet.getColumn(5).width =  12;
            worksheet.getColumn(6).width = 16;
            worksheet.getColumn(7).width = 13.43;
            worksheet.getColumn(8).width = 8.43;
            worksheet.getColumn(9).width = 3.71;

            worksheet.getCell('A' + (1 + index)).value = String(days[i].toUpperCase());
            worksheet.mergeCells('A' + (1 + index) + ':I' + (1 + index));
            worksheet.getCell('A' + (1+index)).fill={
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'dde5f0'}
            }

            worksheet.getCell('A' + (1 + index)).border = {
                top: {style:'medium'},
                left: {style:'medium'},
                bottom: {style:'medium'},
                right: {style:'medium'}
              }
            worksheet.getCell('A' + (1 + index)).style.font = {size: 14, bold:true, name: "Arial"}
            worksheet.getCell('A' + (1 + index)).alignment = {horizontal: 'center'}
            worksheet.getRow(1).height = 15;
            Object.keys(shifts).sort((a, b)=>{
                return jobcodes[a].jobName.localeCompare(jobcodes[b].jobName)
            }).map((shiftKey)=>{
                worksheet.getCell('B' + (row + index)).value = String(jobcodes[shiftKey].jobName);
                worksheet.mergeCells('B' + (row + index) + ':H' + (row + index));
                worksheet.getCell('B' + (row+index)).fill={
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'C7C7C7'}
                }
    
                worksheet.getCell('B' + (row + index)).style.font = {size: 16, bold:true}
                worksheet.getCell('B' + (row + index)).alignment = {horizontal: 'center'}
                worksheet.getRow(index + row).height = 21;
                index += 1;
                shifts[shiftKey].forEach((shift)=>{
                            if(employees[shift.ownerid] && jobcodes[shift.jobId] && shift.roleId.toString() !== "643775091"){
                                worksheet.getRow(row+index).font = {size: 14};
                                worksheet.getRow(row+index).height = 17;
                                worksheet.getRow(row+index).alignment = {horizontal: 'center'}
                                worksheet.getCell("B" + (row + index)).value = employees[shift.ownerid].firstname;
                                worksheet.getCell("C" + (row + index)).value = String(employees[shift.ownerid].lastname);
                                worksheet.getCell("D" + (row + index)).value = String(jobcodes[shift.jobId].jobName);
                                worksheet.getCell("E" + (row + index)).value = locations[shift.locationId] ?  locations[shift.locationId].locationName : ""
                                worksheet.getCell("F" + (row + index)).value = shift.dateTime.format("h:mm A");
                                index += 1
                            } else {
                                return null;
                            }
                        });
                    });
            worksheet.getCell('B' + (row + index)).value = String("Training");
            worksheet.mergeCells('B' + (row + index) + ':H' + (row + index));
            worksheet.getCell('B' + (row+index)).fill={
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'C7C7C7'}
            }

            worksheet.getCell('B' + (row + index)).style.font = {size: 16, bold:true}
            worksheet.getCell('B' + (row + index)).alignment = {horizontal: 'center'}
            worksheet.getRow(index + row).height = 21;
            index += 1;       
            Object.keys(shifts).sort((a, b)=>{
                    return jobcodes[a].jobName.localeCompare(jobcodes[b].jobName)
                }).map((shiftKey)=>{
                shifts[shiftKey].forEach((shift)=>{
                            if(employees[shift.ownerid] && jobcodes[shift.jobId] && shift.roleId.toString() === "643775091"){
                                worksheet.getRow(row+index).font = {size: 14};
                                worksheet.getRow(row+index).height = 17;
                                worksheet.getRow(row+index).alignment = {horizontal: 'center'}
                                worksheet.getCell("B" + (row + index)).value = employees[shift.ownerid].firstname;
                                worksheet.getCell("C" + (row + index)).value = String(employees[shift.ownerid].lastname);
                                worksheet.getCell("D" + (row + index)).value = String(jobcodes[shift.jobId].jobName);
                                worksheet.getCell("E" + (row + index)).value = locations[shift.locationId] ?  locations[shift.locationId].locationName : ""
                                worksheet.getCell("F" + (row + index)).value = shift.dateTime.format("h:mm A");
                                index += 1
                            } else {
                                return null;
                            }
                        });
            });
            worksheet.pageSetup.printArea="A1:I" + (row+index + 1);
            worksheet.pageSetup.fitToPage = true;
        }     

        
        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), startOfWeek.format("MM-DD-YYYY") + ' Boh Teamsheets.xlsx')



   }
    const syncHostschedules = () => {
        let tempTotalMinutes = {};
        let tempTotalCost = {};
        let tempTotalOvertimeCost = {};
        let tempTotalOvertimeMinutes = {};

        enableSnackbar("Loading data")
        axios.get(url + "/teamsheets/boh/update/"+startOfWeek.format("YYYY-MM-DD")).then((res)=>{
            console.log("Synced hotschedules")
            console.log(res.data.data)
            let tempTeamsheets = {...res.data.data.teamsheets};
            if(!res.data.data){
                setFailLoad(true);
                return;
            }
            console.log(res.data)
            Object.keys(tempTeamsheets).forEach((key)=>{
                Object.keys(tempTeamsheets[key]).forEach((jobcodeKey)=>{
                    tempTeamsheets[key][jobcodeKey] = tempTeamsheets[key][jobcodeKey].map((shift)=>{
                        let tempShift = {...shift}
                        let detailText = shift.startDate + " " + shift.startTime;
                        tempShift.dateTime = moment(detailText, "YYYY-MM-DD kk:mm");
                        if(!tempTotalMinutes[key]){
                            tempTotalMinutes[key] = 0;
                            tempTotalCost[key] = 0;

                        } 
                        if(roles[tempShift.roleId]){
                            tempTotalMinutes[key] += tempShift.duration;
                            tempTotalCost[key] += tempShift.totalCost;

                        }
                        return tempShift;
                    })
                })
            })
            console.log(tempTeamsheets)
            setTotalMinutes(tempTotalMinutes);
            setTeamsheet({...tempTeamsheets});
            setTotalOvertimeCost({...tempTotalOvertimeCost})
            setTotalOvertimeMinutes({...tempTotalOvertimeMinutes})
            setTotalCost(tempTotalCost);
            enableSnackbar("Loaded data successfully")

        })
        .catch((error)=>{
            console.log(error);
            setFailLoad(false);
            enableSnackbar("failed to load data")
        })
    }
    const loadTeamsheetData = () => {
        let tempTotalMinutes = {};
        let tempTotalCost = {};
        let tempTotalOvertimeCost = {};
        let tempTotalOvertimeMinutes = {};

        axios.get(url + "/teamsheets/boh/"+startOfWeek.format("YYYY-MM-DD")).then((res)=>{
            console.log(res.data)
            let tempTeamsheets = {...res.data.teamsheets};
            Object.keys(tempTeamsheets).forEach((key)=>{
                Object.keys(tempTeamsheets[key]).forEach((jobcodeKey)=>{
                    tempTeamsheets[key][jobcodeKey] = tempTeamsheets[key][jobcodeKey].map((shift)=>{
                        let tempShift = {...shift}
                        let detailText = shift.startDate + " " + shift.startTime;
                        tempShift.dateTime = moment(detailText, "YYYY-MM-DD kk:mm");
                        if(!tempTotalMinutes[key]){
                            tempTotalMinutes[key] = 0;
                            tempTotalCost[key] = 0;
                            tempTotalOvertimeCost[key] = 0;
                            tempTotalOvertimeMinutes[key] = 0;

                        } 
                        if(roles[tempShift.roleId]){
                            tempTotalMinutes[key] += tempShift.duration;
                            tempTotalCost[key] += tempShift.totalCost;
                            tempTotalOvertimeCost[key] += tempShift.ovtPay;
                            tempTotalOvertimeMinutes[key] += tempShift.ovtHours;


                        }
                        return tempShift;
                    })
                })
            })
            console.log(tempTotalOvertimeCost)
            setTotalMinutes(tempTotalMinutes);
            setTeamsheet(tempTeamsheets);
            setTotalCost(tempTotalCost);
            setTotalOvertimeCost({...tempTotalOvertimeCost})
            setTotalOvertimeMinutes({...tempTotalOvertimeMinutes})
            setEmployees(res.data.employees);
            setLocations(res.data.locations);
            setJobcodes(res.data.jobcodes);

        })
        .catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        loadTeamsheetData();
    }, []);
    useEffect(()=>{
        if(teamsheets[startOfWeek.format("YYYY-MM-DD")] === undefined){
            loadTeamsheetData();
        }
        setCurrentDay(moment(startOfWeek).add(currentTab, "days"))
    }, [startOfWeek])
    const loadDailyData = () => {
        console.log(url + "/financial/data/"+currentDay.format("YYYY-MM-DD"))
        axios.get(url + "/financial/data/"+currentDay.format("YYYY-MM-DD"))
        .then((res)=>{
            let tempFiscalInfo = res.data;
            axios.get(url + `/financial/sales/daily/${tempFiscalInfo.year-1}/${tempFiscalInfo.period}/${tempFiscalInfo.period_week}/${tempFiscalInfo.period_day}`)
            .then((res)=>{
                const tempSalesData = res.data;
                setFiscalInfo(tempFiscalInfo);
                setSalesData(tempSalesData);
            })
            .catch((error)=>{
                console.log(error)
            })
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        loadDailyData();
    }, [currentDay])
    const handleWeekChange = (modifier) =>{
        setCurrentTab(0);
        setStartOfWeek(moment(startOfWeek).add(modifier, "week"))

    }
    const handleTabChange = (event, value) => {
        setCurrentTab(value);
        setCurrentDay(moment(startOfWeek).add(value, "days"));
    }

    return(
        <React.Fragment>
            {
                failLoad ? "Error" :
                <Box
                >
                    <Snackbar
                        open={snackbarState}
                        autoHideDuration={3000}
                        onClose={snackbarClose}
                        message={snackbarMessage}

                    />

                    <Paper
                        sx={{
                            maxWidth: {xs: 360, sm: 560, md: 640, lg: 1440},
                            margin: "auto"
                        }}
                    >
                        <Typography 
                            variant="h4"
                            display="flex" 
                            justifyContent="center" 
                            sx={{
                                alignItems:"center"
                            }} 
                        >
                            {startOfWeek ? ("Week of " + startOfWeek.format("MM/DD/YYYY")) : "Loading Week Data"}                 
                        </Typography>
                        <div
                                sx={{
                                    margin: "80px"}
                                }
                            >
                                <Button
                                    id="demo-customized-button"
                                    aria-haspopup="true"
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    Tools
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={()=>{{
                                        syncHostschedules();
                                        handleClose();
                                    }}}>
                                        <ListItemIcon>
                                            <SyncIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Sync Hotschedules
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={
                                        ()=>{{
                                            downloadTeamsheets();
                                            handleClose()
                                        }}
                                        }>                                    
                                        <ListItemIcon>
                                            <DownloadIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Download Teamsheets
                                        </ListItemText>
                                        
                                    </MenuItem>
                                    <MenuItem onClick={()=>{{
                                        downloadLaborTracker();
                                        handleClose()
                                    }}
                                    }>
                                        <ListItemIcon>
                                            <FolderSharedIcon />

                                        </ListItemIcon>
                                        <ListItemText>
                                            Download Labor Tracker
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </div>
                        <Box display="flex" justifyContent="center" sx={{
                            alignItems:"center"
                        }} width="100%">
                            <IconButton
                                onClick={()=>{
                                    handleWeekChange(-1)
                                }}
                            ><ArrowBackIosIcon /></IconButton>
                            <Tabs 
                                value={currentTab} 
                                onChange={handleTabChange}
                                variant="scrollable"
                                allowScrollButtonsMobile

                            >
                                <Tab label = "Wednesday" />
                                <Tab label = "Thursday" />
                                <Tab label = "Friday" />
                                <Tab label = "Saturday" />
                                <Tab label = "Sunday" />
                                <Tab label = "Monday" />
                                <Tab label = "Tuesday" />


                            </Tabs>
                            <IconButton
                                onClick={()=>{
                                    handleWeekChange(1)
                                }}
                            ><ArrowForwardIosIcon /></IconButton>
                        </Box>
                        <Table
                        >
                            <TableBody>
                                    {salesData ? salesData['sales']     ? 
                                    <React.Fragment>
                                        <MobileOverviewTable
                                            sx={{
                                                display: {sm: 'table-row', xs: 'table-row', md: 'none'}
                                            }}
                                            salesData = {salesData}
                                            totalMinutes = {totalMinutes}
                                            totalCost = {totalCost}
                                            currentDay = {currentDay}
                                            totalOvertimeMinutes = {totalOvertimeMinutes}
                                            totalOvertimeCost = {totalOvertimeCost}

                                        />
                                        <OverviewTable 
                                            sx={{
                                                display: {sm: 'none', xs: 'none', md: 'table-row'}
                                            }}
                                            salesData = {salesData}
                                            totalMinutes = {totalMinutes}
                                            totalCost = {totalCost}
                                            currentDay = {currentDay}
                                            totalOvertimeMinutes = {totalOvertimeMinutes}
                                            totalOvertimeCost = {totalOvertimeCost}

                                        />
                                    </React.Fragment>
                                    : "": "" }
                                    
                                {
                                    <ShiftTable 
                                        shifts={teamsheets[moment(startOfWeek).add(currentTab, "days").format("YYYY-MM-DD")]}
                                        employees={employees}
                                        jobcodes={jobcodes}
                                        locations={locations}
                                    />
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>
            }
        </React.Fragment>

    )
}
const MobileOverviewTable = (props) => {
    const {currentDay, salesData, totalMinutes, totalCost, totalOvertimeCost, totalOvertimeMinutes, sx} = {...props};
    return(
        <React.Fragment >
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    Sales: 
                    <br />
                    {formatMoney((salesData['sales']*1.05), 2, ".", ",")} 
                </TableCell>
                <TableCell colSpan={2}>
                    Guests: <br />{Math.ceil((salesData['guest_count']*1.01))}
                </TableCell>
            </TableRow>
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    GPMH: {salesData['guest_count'] && totalMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? (salesData['guest_count'] / (totalMinutes[currentDay.format("YYYY-MM-DD")]/60)).toFixed(2)  : "N?A"}
                </TableCell>
                <TableCell colSpan={2}>
                    Hours: {salesData['guest_count'] && totalMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? (totalMinutes[currentDay.format("YYYY-MM-DD")]/60)  : "N?A"}
                </TableCell>
            </TableRow>
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    Labor: {(totalCost[currentDay.format("YYYY-MM-DD")]/(salesData['sales'] * 1.05)).toFixed(2)}%
                </TableCell>
                <TableCell colSpan={2}>
                    Labor $: {(totalCost[currentDay.format("YYYY-MM-DD")]/100).toFixed(2)}
                </TableCell>
            </TableRow>
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    Overtime Cost: { totalOvertimeCost[currentDay.format("YYYY-MM-DD")] !== 0 ? (formatMoney(totalOvertimeCost[currentDay.format("YYYY-MM-DD")]/100, 2, ".", ","))  : "$0.00"}
                </TableCell>
                <TableCell colSpan={2}>
                    Overtime Hours: { totalOvertimeMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? totalOvertimeMinutes[currentDay.format("YYYY-MM-DD")]  : "0"}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
const OverviewTable = (props) => {
    const {currentDay, salesData, totalMinutes, totalCost, totalOvertimeMinutes, totalOvertimeCost, sx} = {...props}
    return(
        <React.Fragment >
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={3}>
                    Projected Sales: {formatMoney((salesData['sales']*1.05), 2, ".", ",")} 
                </TableCell>
                <TableCell colSpan={2}>
                    Projected Guests: {Math.ceil((salesData['guest_count']*1.01))}
                </TableCell>
            </TableRow>
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    GPMH {salesData['guest_count'] && totalMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? (salesData['guest_count'] / (totalMinutes[currentDay.format("YYYY-MM-DD")]/60)).toFixed(2)  : "N?A"}
                </TableCell>
                <TableCell colSpan={1}>
                    Hours: {salesData['guest_count'] && totalMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? (totalMinutes[currentDay.format("YYYY-MM-DD")]/60)  : "N?A"}
                </TableCell>
                <TableCell colSpan={1}>
                    Labor: {(totalCost[currentDay.format("YYYY-MM-DD")]/salesData['sales']).toFixed(2)}%
                </TableCell>
                <TableCell colSpan={1}>
                    Labor $: {(totalCost[currentDay.format("YYYY-MM-DD")]/100).toFixed(2)}
                </TableCell>
            </TableRow>
            <TableRow sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                <TableCell colSpan={2}>
                    Overtime Cost: { totalOvertimeCost[currentDay.format("YYYY-MM-DD")] !== 0 ? (formatMoney(totalOvertimeCost[currentDay.format("YYYY-MM-DD")]/100, 2, ".", ","))  : "$0.00"}
                </TableCell>
                <TableCell colSpan={1}>
                    Overtime Effect: { totalOvertimeMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? (((totalOvertimeCost[currentDay.format("YYYY-MM-DD")]/100)/(salesData['sales']*1.05))*100).toFixed(2) +'%'   : "0.0%"}
                </TableCell>
                <TableCell colSpan={1}>
                    Overtime Hours: { totalOvertimeMinutes[currentDay.format("YYYY-MM-DD")] !== 0 ? totalOvertimeMinutes[currentDay.format("YYYY-MM-DD")]  : "0"}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
const ShiftTable = (props) => {
    const {shifts, employees, locations, jobcodes} = {...props};
    if(shifts && Object.keys(shifts).length > 0 && employees && locations && Object.keys(jobcodes).length !== 0){
        return(
            <React.Fragment>
                
                {
                    Object.keys(shifts).sort((a, b)=>{
                        return jobcodes[a].jobName.localeCompare(jobcodes[b].jobName)
                    }).map((shiftKey)=>{
                        return (
                            <React.Fragment
                            key={shiftKey}

                            >
                                <TableRow>
                                    <TableCell
                                        align="center" colSpan={5}
                                        sx={{
                                            backgroundColor:"#eeeeee"
                                        }}    
                                    >
                                        <Typography variant="h6">{jobcodes[shiftKey].jobName}</Typography>
                                    </TableCell>
                                </TableRow>
                                {
                                shifts[shiftKey].map((shift)=>{
                                    if(employees[shift.ownerid] && jobcodes[shift.jobId] && shift.roleId.toString() !== "643775091"){
                                        return(
                                            <TableRow
                                                key={shift.ownerid +""+ shift.startTime}
                                            >
                                                <TableCell>

                                                        {employees[shift.ownerid].name}
                                                </TableCell>
                                                <TableCell
                                                >{jobcodes[shift.jobId].jobName}</TableCell>
                                                <TableCell>{locations[shift.locationId] ?  locations[shift.locationId].locationName : ""}</TableCell>
                                                <TableCell>{shift.dateTime.format("h:mm A")}</TableCell>
                                                <TableCell>{moment(shift.dateTime).add(shift.duration, "minutes").format("h:mm A")}</TableCell>

                                            </TableRow>
                                        )
                                    }
                                })
                                }
                            </React.Fragment>
                        )
                    })
                }
                <TableRow>
                    <TableCell
                        align="center" colSpan={5}
                        sx={{
                            backgroundColor:"#eeeeee"
                        }}    
                    >
                        <Typography variant="h6">TRAINING</Typography>
                    </TableCell>
                </TableRow>
                {
                Object.keys(shifts).sort((a, b)=>{
                        return jobcodes[a].jobName.localeCompare(jobcodes[b].jobName)
                    }).map((shiftKey)=>{
                                return (shifts[shiftKey].map((shift)=>{
                                    if(employees[shift.ownerid] && jobcodes[shift.jobId] && shift.roleId.toString() === "643775091"){
                                        return(
                                            <TableRow
                                                key={shift.ownerid +""+ shift.startTime}
                                            >
                                                <TableCell>{employees[shift.ownerid].name}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        display: {sm: 'none', xs: 'none', md: 'table-cell'}
                                                    }}
                                                >{jobcodes[shift.jobId].jobName}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        display: {xs: 'none',sm: 'none', xs: 'none', md: 'table-cell'}
                                                    }}   
                                                >{locations[shift.locationId] ?  locations[shift.locationId].locationName : ""}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        display: {sm: 'table-cell', md: 'none', lg: 'none', xl: 'none'}
                                                    }}
                                                >
                                                    {locations[shift.locationId] ? jobcodes[shift.jobId].jobName + " - " + locations[shift.locationId].locationName : jobcodes[shift.jobId].jobName}
                                                </TableCell>
                                                <TableCell>{shift.dateTime.format("h:mm A")}</TableCell>
                                                <TableCell>{moment(shift.dateTime).add(shift.duration, "minutes").format("h:mm A")}</TableCell>

                                            </TableRow>
                                        )
                                    } else {
                                        return null;
                                    }
                                }));
                               
                    })
                }
            </React.Fragment>
        )
    } else {
        return(
            <React.Fragment>
                <TableRow>
                    <TableCell
                        align="center" colSpan={4}>
                        <Typography variant="h6">No data available</Typography>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

}